import './CostBlock.scss';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import Typography from '@components/Typography';
import multiplyIcon from './assets/multiply.svg';

interface CostBlockProps {
  cost: PricingDetailsType;
  type: ConsumptionTypes;
  mobileOnly?: boolean;
  costDescription: string;
}

const CostBlock: React.FC<CostBlockProps> = (props) => {
  const { cost = { value: 0, scale: '', cost: 0, costScale: '' }, type, mobileOnly, costDescription } = props;
  const totalCost = useMemo(() => parseFloat((cost.value * cost.cost).toFixed(4)), [cost.value, cost.cost]);

  return (
    <div
      className={classNames('TDB-PricingCalculator__Cost', `TDB-PricingCalculator__Cost--${type}`, {
        'TDB-PricingCalculator__Cost--mobile': mobileOnly,
      })}
    >
      <div className="TDB-PricingCalculator__Cost__Details">
        <div className="TDB-PricingCalculator__Cost__Row TDB-PricingCalculator__Cost__Row--label">
          <Typography className="TDB-PricingCalculator__Cost__Label" fontWeight="medium">
            {costDescription}
          </Typography>
          <Typography className="TDB-PricingCalculator__Cost__Label" fontWeight="medium">
            Cost
          </Typography>
        </div>
        <div className="TDB-PricingCalculator__Cost__Row TDB-PricingCalculator__Cost__Row--value">
          <Typography className="TDB-PricingCalculator__Cost__Value" fontSize="body-lg" fontWeight="bold">
            {cost.value}
          </Typography>
          <img className="TDB-PricingCalculator__Cost__MultiplyIcon" src={multiplyIcon} alt="multiply" />
          <Typography className="TDB-PricingCalculator__Cost__Value" fontSize="body-lg" fontWeight="bold" align="right">
            $ {cost.cost}
          </Typography>
        </div>
        <div className="TDB-PricingCalculator__Cost__Row TDB-PricingCalculator__Cost__Row--description">
          <Typography className="TDB-PricingCalculator__Cost__Description" fontSize="body-sm" fontWeight="medium">
            {cost.scale}
          </Typography>
          <Typography className="TDB-PricingCalculator__Cost__Description" fontSize="body-sm" fontWeight="medium">
            {cost.costScale}
          </Typography>
        </div>
      </div>
      <div className="TDB-PricingCalculator__Cost__TotalCost">
        <div className="TDB-PricingCalculator__Cost__Row TDB-PricingCalculator__Cost__Row--total">
          <Typography className="TDB-PricingCalculator__Cost__TotalLabel" color="neutral-600" fontSize="body-lg" fontWeight="medium">
            Total charge
          </Typography>
          <Typography className="TDB-PricingCalculator__Cost__TotalValue" fontSize="body-lg" fontWeight="bold">
            $ {totalCost}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CostBlock;
