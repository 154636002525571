import './InputSelect.scss';
import React from 'react';
import Select, { Props } from 'react-select';
import classNames from 'classnames';

const InputSelect: React.FC<Props> = (props) => {
  const { className } = props;
  return <Select {...props} className={classNames('TDB-InputSelect', className)} classNamePrefix="TDB-InputSelect" />;
};

export default InputSelect;
