import './PageHeader.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';

const PageHeader: React.FC = () => {
  return (
    <div className="TDB-ConsumptionPage__PageHeader">
      <Container maxWidth={890}>
        <div className="TDB-ConsumptionPage__PageHeader__Background" />
        <div className="TDB-ConsumptionPage__PageHeader__GlowBackground" />
        <div className="TDB-ConsumptionPage__PageHeader__Wrapper">
          <Typography
            className="TDB-ConsumptionPage__PageHeader__Title"
            fontSize="display-1"
            fontWeight="bold"
            color="neutral-900"
            align="center"
            as="h2"
          >
            TileDB Pricing
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default PageHeader;
