import './PricingSlider.scss';
import React from 'react';
import ReactSlider from 'react-slider';
import classNames from 'classnames';
import Typography from '@components/Typography';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import thumbIcon from './assets/thumb-icon.svg';

interface PricingSliderProps {
  onChange: (e: any) => void;
  markLabels: { [index: string]: string };
  highlighsMarks: number[];
  type: ConsumptionTypes;
  min: number;
  max: number;
  value: number;
}

const PricingSlider: React.FC<PricingSliderProps> = (props) => {
  const { onChange, markLabels = {}, highlighsMarks = [], type, min, max, value } = props;
  const markIndexesToRender2 = Object.keys(markLabels).map((index) => Number(index));

  return (
    <ReactSlider
      className={classNames('TDB-PricingSlider', `TDB-PricingSlider--${type}`)}
      marks={markIndexesToRender2}
      markClassName="TDB-PricingSlider__MarkWrapper"
      renderMark={(markProps: any) => {
        const highlight = highlighsMarks.some((i) => i === markProps.key);
        return (
          <div {...markProps}>
            <Typography
              className={classNames('TDB-PricingSlider__Mark', `TDB-PricingSlider__Mark--${type}`, {
                'TDB-PricingSlider__Mark--highlight': highlight,
                'TDB-PricingSlider__Mark--mobile-hide': markProps.key % 2 === 0,
              })}
              fontSize="body-sm"
              fontWeight="semi-bold"
            >
              {markLabels[markProps.key]}
            </Typography>
          </div>
        );
      }}
      min={min}
      max={max}
      thumbClassName={classNames('TDB-PricingSlider__Thumb', `TDB-PricingSlider__Thumb--${type}`)}
      trackClassName={classNames('TDB-PricingSlider__Track', `TDB-PricingSlider__Track--${type}`)}
      renderThumb={(thumbProps: any) => (
        <div {...thumbProps}>
          <img src={thumbIcon} alt="" />
        </div>
      )}
      onChange={onChange}
      value={value}
    />
  );
};

export default PricingSlider;
