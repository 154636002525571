import './PricingDetails.scss';
import React, { useMemo } from 'react';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import Typography from '@components/Typography';
import CostBlock from '../CostBlock';

interface PricingDetailsProps {
  notebooksPricingDetails: PricingDetailsType;
  slicingPricingDetails: PricingDetailsType;
  SQLPricingDetails: PricingDetailsType;
  UDFPricingDetails: PricingDetailsType;
  egressPricingDetails: PricingDetailsType;
}

const PricingDetails: React.FC<PricingDetailsProps> = (props) => {
  const {
    notebooksPricingDetails = { value: 0, scale: '', cost: 0, costScale: '' },
    slicingPricingDetails = { value: 0, scale: '', cost: 0, costScale: '' },
    SQLPricingDetails = { value: 0, scale: '', cost: 0, costScale: '' },
    UDFPricingDetails = { value: 0, scale: '', cost: 0, costScale: '' },
    egressPricingDetails = { value: 0, scale: '', cost: 0, costScale: '' },
  } = props;

  const notebookTotalCost = useMemo(
    () => parseFloat((notebooksPricingDetails.value * notebooksPricingDetails.cost).toFixed(4)),
    [notebooksPricingDetails.value, notebooksPricingDetails.cost]
  );
  const slicingTotalCost = useMemo(
    () => parseFloat((slicingPricingDetails.value * slicingPricingDetails.cost).toFixed(4)),
    [slicingPricingDetails.value, slicingPricingDetails.cost]
  );
  const SQLTotalCost = useMemo(
    () => parseFloat((SQLPricingDetails.value * SQLPricingDetails.cost).toFixed(4)),
    [SQLPricingDetails.value, SQLPricingDetails.cost]
  );
  const UDFTotalCost = useMemo(
    () => parseFloat((UDFPricingDetails.value * UDFPricingDetails.cost).toFixed(4)),
    [UDFPricingDetails.value, UDFPricingDetails.cost]
  );
  const egressTotalCost = useMemo(
    () => parseFloat((egressPricingDetails.value * egressPricingDetails.cost).toFixed(4)),
    [egressPricingDetails.value, egressPricingDetails.cost]
  );

  const totalCost = useMemo(
    () => notebookTotalCost + slicingTotalCost + SQLTotalCost + UDFTotalCost + egressTotalCost,
    [notebookTotalCost, slicingTotalCost, SQLTotalCost, UDFTotalCost, egressTotalCost]
  );
  return (
    <>
      <div className="TDB-PricingCalculator__DetailsHelper" />
      <div className="TDB-PricingCalculator__Details">
        <div className="TDB-PricingCalculator__Details__Block TDB-PricingCalculator__Details__Block--notebooks">
          <Typography
            as="p"
            className="TDB-PricingCalculator__Details__Title TDB-PricingCalculator__Details__Title--notebooks"
            fontSize="subheading-2"
            fontWeight="semi-bold"
          >
            Applications costs
          </Typography>
          <CostBlock cost={notebooksPricingDetails} type={ConsumptionTypes.Notebooks} costDescription="Running time" />
        </div>
        <div className="TDB-PricingCalculator__Details__Hr" />
        <div className="TDB-PricingCalculator__Details__Block TDB-PricingCalculator__Details__Block--tasks">
          <Typography
            as="p"
            className="TDB-PricingCalculator__Details__Title TDB-PricingCalculator__Details__Title--tasks"
            fontSize="subheading-2"
            fontWeight="semi-bold"
          >
            Task Costs
          </Typography>
          <div className="TDB-PricingCalculator__Details__SubBlock">
            <CostBlock cost={slicingPricingDetails} type={ConsumptionTypes.Tasks} costDescription="Slicing duration" />
          </div>
          <div className="TDB-PricingCalculator__Details__SubBlock">
            <CostBlock cost={SQLPricingDetails} type={ConsumptionTypes.Tasks} costDescription="SQL Query" />
          </div>
          <CostBlock cost={UDFPricingDetails} type={ConsumptionTypes.Tasks} costDescription="Running time" />
          <div className="TDB-PricingCalculator__Details__Hr" />
          <div className="TDB-PricingCalculator__Details__Block TDB-PricingCalculator__Details__Block--egress">
            <Typography
              as="p"
              className="TDB-PricingCalculator__Details__Title TDB-PricingCalculator__Details__Title--egress"
              fontSize="subheading-2"
              fontWeight="semi-bold"
            >
              Egress Costs
            </Typography>
            <CostBlock cost={egressPricingDetails} type={ConsumptionTypes.Egress} costDescription="Data volume" />
          </div>
        </div>
        <div className="TDB-PricingCalculator__Details__Hr TDB-PricingCalculator__Details__Hr--total" />
        <div className="TDB-PricingCalculator__Details__Row TDB-PricingCalculator__Details__Row--total">
          <Typography className="TDB-PricingCalculator__Details__TotalLabel" color="neutral-600" fontSize="body-lg" fontWeight="semi-bold">
            Total cost
          </Typography>
          <Typography className="TDB-PricingCalculator__Details__TotalValue" fontSize="body-lg" fontWeight="bold" color="neutral-800">
            $ {totalCost.toFixed(2)}
          </Typography>
        </div>
        <div className="TDB-PricingCalculator__Details__Receipt">
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
          <div className="TDB-PricingCalculator__Details__Receipt__arrow" />
        </div>
      </div>
    </>
  );
};

export default PricingDetails;
