import './ConsumptionOverview.scss';
import React from 'react';
import classNames from 'classnames';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import Typography from '@components/Typography';
import Container from '@components/Container';
import ConsumptionTypeTitle from '../components/ConsumptionTypeTitle';

interface ConsumptionSectionProps {
  type: ConsumptionTypes;
  description: string;
}

const ConsumptionSection: React.FC<ConsumptionSectionProps> = (props) => {
  const { type, description, children } = props;

  return (
    <div className={classNames('TDB-ConsumptionPage__Overview__Section', `TDB-ConsumptionPage__Overview__Section--${type}`)}>
      <div className="TDB-ConsumptionPage__Overview__Section__Header">
        <ConsumptionTypeTitle type={type} className="TDB-ConsumptionPage__Overview__Section__Title" />
        <Typography className="TDB-ConsumptionPage__Overview__Section__Description" fontWeight="medium" as="p">
          {description}
        </Typography>
      </div>
      <div className="TDB-ConsumptionPage__Overview__Section__Children">{children}</div>
    </div>
  );
};

interface ConsumptionSectionItemProps {
  title: string;
  type: ConsumptionTypes;
  description?: string;
  charge: {
    value: number;
    per: 'hr' | 'GB';
  };
  resources?: { value: number; text: string }[];
}

const ConsumptionSectionItem: React.FC<ConsumptionSectionItemProps> = (props) => {
  const { type, title, description, charge = { value: 0, per: '' }, resources = [] } = props;

  return (
    <div className={classNames('TDB-ConsumptionPage__Overview__SectionItem', `TDB-ConsumptionPage__Overview__SectionItem--${type}`)}>
      <Typography as="h6" className="TDB-ConsumptionPage__Overview__SectionItem__Title" fontWeight="semi-bold">
        {title}
      </Typography>
      <Typography className="TDB-ConsumptionPage__Overview__SectionItem__Subtitle" as="p" fontSize="body-lg" fontWeight="semi-bold">
        $ <span className="TDB-ConsumptionPage__Overview__SectionItem__Subtitle__Value">{charge.value}</span> / {charge.per}
      </Typography>

      <div className="TDB-ConsumptionPage__Overview__SectionItem__Resources">
        {description && (
          <Typography fontWeight="medium" className="TDB-ConsumptionPage__Overview__SectionItem__Resources__Description">
            {description}
          </Typography>
        )}
        <Typography>
          {resources.map((resource) => {
            return (
              <Typography
                className="TDB-ConsumptionPage__Overview__SectionItem__Resources__Item"
                key={`${resource.value}-${resource}`}
                fontWeight="medium"
                as="p"
              >
                <strong>{resource.value}</strong> {resource.text}
              </Typography>
            );
          })}
        </Typography>
      </div>
    </div>
  );
};

const ConsumptionOverview: React.FC = () => {
  return (
    <Container maxWidth={1060}>
      <div id="consumption-overview" className="TDB-ConsumptionPage__Overview__Header">
        <Typography
          className="TDB-ConsumptionPage__Overview__Description"
          align="center"
          as="h5"
          color="neutral-600"
          fontWeight="medium"
          fontSize="subheading-3"
        >
          TileDB Cloud SaaS features usage-based pricing and is cost-efficient for a wide range of queries and access patterns. Account
          credits are also consumed using this pricing model.
        </Typography>
      </div>
      <div className="TDB-ConsumptionPage__Overview">
        <ConsumptionSection type={ConsumptionTypes.Tasks} description="The basic units of computation in TileDB Cloud SaaS.">
          <ConsumptionSectionItem
            title="Slicing"
            type={ConsumptionTypes.Tasks}
            charge={{ value: 1.76, per: 'hr' }}
            resources={[
              { value: 16, text: 'CPUs' },
              { value: 2, text: 'GB RAM' },
            ]}
          />
          <ConsumptionSectionItem
            title="Serverless SQL"
            type={ConsumptionTypes.Tasks}
            charge={{ value: 1.76, per: 'hr' }}
            resources={[
              { value: 16, text: 'CPUs' },
              { value: 2, text: 'GB RAM' },
            ]}
          />
          <ConsumptionSectionItem
            title="Small UDFs"
            type={ConsumptionTypes.Tasks}
            charge={{ value: 0.22, per: 'hr' }}
            resources={[
              { value: 2, text: 'CPUs' },
              { value: 2, text: 'GB RAM' },
            ]}
          />
          <ConsumptionSectionItem
            title="Large UDFs"
            type={ConsumptionTypes.Tasks}
            charge={{ value: 0.88, per: 'hr' }}
            resources={[
              { value: 8, text: 'CPUs' },
              { value: 8, text: 'GB RAM' },
            ]}
          />
        </ConsumptionSection>
        <div className="TDB-ConsumptionPage__Overview__BottomWrapper">
          <ConsumptionSection
            type={ConsumptionTypes.Notebooks}
            description="Choose different server configurations for running your TileDB apps, such as notebooks and dashboards."
          >
            <ConsumptionSectionItem
              title="Small Server"
              type={ConsumptionTypes.Notebooks}
              charge={{ value: 0.12, per: 'hr' }}
              resources={[
                { value: 2, text: 'CPUs' },
                { value: 8, text: 'GB RAM' },
                { value: 2, text: 'GB EBS' },
              ]}
            />
            <ConsumptionSectionItem
              title="Large Server"
              type={ConsumptionTypes.Notebooks}
              charge={{ value: 0.96, per: 'hr' }}
              resources={[
                { value: 16, text: 'CPUs' },
                { value: 60, text: 'GB RAM' },
                { value: 2, text: 'GB EBS' },
              ]}
            />
          </ConsumptionSection>
          <ConsumptionSection
            type={ConsumptionTypes.Egress}
            description="The amount of data retrieved from the service to your client, not the data processed by a query."
          >
            <ConsumptionSectionItem
              title="Fixed rate"
              type={ConsumptionTypes.Egress}
              charge={{ value: 0.14, per: 'GB' }}
              description="Rounded to the nearest whole GB at the end of the month."
            />
          </ConsumptionSection>
        </div>
      </div>
    </Container>
  );
};

export default ConsumptionOverview;
