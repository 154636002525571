import React from 'react';

const InformationIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M7 0.333313C10.682 0.333313 13.6667 3.31798 13.6667 6.99998C13.6667 10.682 10.682 13.6666 7 13.6666C3.318 13.6666 0.333328 10.682 0.333328 6.99998C0.333328 3.31798 3.318 0.333313 7 0.333313ZM7 6.33331H5V7.33331H5.33333C5.7 7.33331 6 7.62665 6 7.99998V9.99998C6 10.5466 6.44666 11 7 11H9V9.99998H8.66666C8.29333 9.99998 8 9.69998 8 9.33331V7.33331C8 6.77998 7.54666 6.33331 7 6.33331ZM7 3.16665C6.35333 3.16665 5.83333 3.68665 5.83333 4.33331C5.83333 4.97331 6.35333 5.49998 7 5.49998C7.64 5.49998 8.16666 4.97331 8.16666 4.33331C8.16666 3.68665 7.64 3.16665 7 3.16665Z"
      fill="#A6A6A6"
    />
  </svg>
);

export default InformationIcon;
