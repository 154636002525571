import React from 'react';

const SeatsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0714 14H18.2857C18.3571 14.3571 18.4286 14.7143 18.4286 15.0714V18.4286C18.4286 18.7143 18.3571 18.9286 18.2857 19.1429H21.2857C21.6429 19.1429 22 18.7857 22 18.4286V15.9286C22 14.9286 21.1429 14 20.0714 14Z"
      fill="currentColor"
    />
    <path
      d="M20.5 10.5C20.5 9.07143 19.3571 7.92857 18 7.92857C16.6429 7.92857 15.5 9.07143 15.5 10.5C15.5 11.9286 16.6429 13.0714 18 13.0714C19.3571 13.0714 20.5 11.8571 20.5 10.5Z"
      fill="currentColor"
    />
    <path
      d="M5.71429 14H3.92857C2.85714 14 2 14.8571 2 15.9286V18.4286C2 18.7857 2.35714 19.1429 2.71429 19.1429H5.64286C5.57143 19 5.57143 18.7143 5.57143 18.5V15.1429C5.5 14.7143 5.57143 14.3571 5.71429 14Z"
      fill="currentColor"
    />
    <path
      d="M8.42857 10.5C8.42857 9.07143 7.28571 7.92857 5.92857 7.92857C4.57143 7.92857 3.42857 9.07143 3.42857 10.5C3.42857 11.9286 4.57143 13.0714 5.92857 13.0714C7.28571 13.0714 8.42857 11.8571 8.42857 10.5Z"
      fill="currentColor"
    />
    <path
      d="M14.7143 12.7857H9.35714C8.07143 12.7857 7 13.8571 7 15.1429V18.5C7 18.9286 7.28571 19.2143 7.71429 19.2143H16.2857C16.7143 19.2143 17 18.9286 17 18.5V15.1429C17 13.8571 16 12.7857 14.7143 12.7857Z"
      fill="currentColor"
    />
    <path
      d="M11.9286 11.4286C13.6429 11.4286 15.0714 10 15.0714 8.21429C15.0714 6.42857 13.6429 5 11.9286 5C10.2143 5 8.78571 6.42857 8.78571 8.21429C8.78571 10 10.1429 11.4286 11.9286 11.4286Z"
      fill="currentColor"
    />
  </svg>
);

export default SeatsIcon;
