import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import InputSelect from '@components/InputSelect';
import pluralize from '@utils/helpers/pluralize';
import CalculatorSection from '../../CalculatorSection';
import CalculatorHeader from '../../CalculatorSection/CalculatorHeader';
import OptionWithScaleIcon from '../../OptionWithScaleIcon';
import CostBlock from '../../CostBlock';

const scaleOptions = [
  {
    label: <OptionWithScaleIcon>Scale: Seconds</OptionWithScaleIcon>,
    value: 'seconds',
  },
  {
    label: <OptionWithScaleIcon>Scale: Minutes</OptionWithScaleIcon>,
    value: 'minutes',
  },
];

const minutesMarkLabels: any = {
  0: '0m',
  10: '10m',
  20: '20m',
  30: '30m',
  40: '40m',
  50: '50m',
  60: '1hr',
  70: '70m',
  80: '80m',
  90: '90m',
  100: '100m',
  110: '110m',
  120: '2hrs',
};

const secondsMarkLabels: any = {
  0: '0s',
  15: '15s',
  30: '30s',
  45: '45s',
  60: '1m',
  75: '75s',
  90: '9s',
  105: '105s',
  120: '2m',
  135: '135s',
  150: '150s',
  165: '165s',
  180: '3m',
};

const costs: any = {
  seconds: 0.00049,
  minutes: 0.029,
};

const scaleDescriptions: any = {
  seconds: 'second',
  minutes: 'minute',
};

const scaleShortDescriptions: any = {
  seconds: 'sec',
  minutes: 'min',
};

const highlightMarks = [60, 120, 180];

interface SlicingCalculatorProps {
  onChange: (data: PricingDetailsType) => void;
}

const SlicingCalculator: React.FC<SlicingCalculatorProps> = (props) => {
  const { onChange } = props;
  const [secondsValue, setSecondsValue] = useState(15);
  const [minutesValue, setMinutesValue] = useState(10);
  const [scaleValue, setScaleValue] = useState(scaleOptions[0]);
  const [cost, setCost] = useState({ value: 0, cost: 0, scale: '', costScale: '' });

  const scale = useMemo(() => {
    return scaleValue.value;
  }, [scaleValue]);

  const markLabels = useMemo(() => {
    if (scaleValue.value === 'minutes') {
      return minutesMarkLabels;
    }
    if (scaleValue.value === 'seconds') {
      return secondsMarkLabels;
    }
    return [];
  }, [scaleValue.value]);

  const value = useMemo(() => {
    let v = 0;
    if (scaleValue.value === 'minutes') {
      v = minutesValue;
    } else if (scaleValue.value === 'seconds') {
      v = secondsValue;
    }
    return v;
  }, [scaleValue, minutesValue, secondsValue]);

  useEffect(() => {
    const c = costs[scaleValue.value];
    let costScale = '';
    if (scaleValue.value === 'minutes') {
      costScale = 'per minute';
    } else if (scaleValue.value === 'seconds') {
      costScale = 'per second';
    }

    const scaleDescr = pluralize(value, scaleDescriptions[scaleValue.value], `${scaleDescriptions[scaleValue.value]}s`);

    setCost({ value, cost: c, scale: scaleDescr, costScale });
  }, [value, scaleValue]);

  useEffect(() => {
    onChange(cost);
  }, [cost]);

  const max = useMemo(() => {
    if (scaleValue.value === 'seconds') {
      return 180;
    }
    if (scaleValue.value === 'minutes') {
      return 120;
    }
    return 0;
  }, [scaleValue]);

  const handleSliderChange = useCallback(
    (v: number) => {
      if (scaleValue.value === 'seconds') {
        setSecondsValue(v);
      }
      if (scaleValue.value === 'minutes') {
        setMinutesValue(v);
      }
    },
    [scaleValue]
  );

  return (
    <div className="TDB-CalculatorSection__Wrapper">
      <CalculatorSection
        type={ConsumptionTypes.Tasks}
        header={
          <CalculatorHeader title="Slicing" type={ConsumptionTypes.Tasks}>
            <InputSelect options={scaleOptions} value={scaleValue} onChange={(newValue: any) => setScaleValue(newValue)} />
          </CalculatorHeader>
        }
        description="Time it takes to slice an array."
        onSliderChange={handleSliderChange}
        displayValue={{
          value,
          description: pluralize(value, scaleShortDescriptions[scale], `${scaleShortDescriptions[scale]}s`),
        }}
        sliderValue={value}
        markLabels={markLabels}
        highlightMarks={highlightMarks}
        min={0}
        max={max}
      />
      <CostBlock cost={cost} type={ConsumptionTypes.Tasks} costDescription="Slicing duration" mobileOnly />
    </div>
  );
};

export default SlicingCalculator;
