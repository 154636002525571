import './CalculatorSection.scss';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Typography from '@components/Typography';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import PricingSlider from './PricingSlider';

interface CalculatorSectionProps {
  type: ConsumptionTypes;
  header: ReactNode;
  description: string;
  displayValue: {
    value: number;
    description: string;
  };
  onSliderChange: (value: number) => void;
  markLabels: { [index: string]: string };
  highlightMarks: number[];
  min: number;
  max: number;
  sliderValue: number;
}

const CalculatorSection: React.FC<CalculatorSectionProps> = (props) => {
  const {
    header,
    type,
    description,
    displayValue = { value: 0, description: '' },
    onSliderChange,
    markLabels,
    highlightMarks,
    min,
    max,
    sliderValue,
  } = props;

  return (
    <div className={classNames('TDB-CalculatorSection', `TDB-CalculatorSection--${type}`)}>
      {header}
      <Typography className="TDB-CalculatorSection__Title" fontSize="heading-4" fontWeight="bold" as="p">
        <span className="TDB-CalculatorSection__Title__Value">{displayValue.value}</span> {displayValue.description}
      </Typography>
      <Typography className="TDB-CalculatorSection__Description" fontWeight="medium" as="p">
        {description}
      </Typography>
      <PricingSlider
        onChange={onSliderChange}
        markLabels={markLabels}
        highlighsMarks={highlightMarks}
        type={type}
        min={min}
        max={max}
        value={sliderValue}
      />
    </div>
  );
};

export default CalculatorSection;
