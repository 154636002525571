import React from 'react';

const CancelIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 10.8215L8.42108 7.24257C8.28108 7.10257 8.17131 7.03656 8.05341 7.00082C7.9355 6.96509 7.81492 6.96509 7.69701 7.00082C7.57911 7.03656 7.46934 7.10257 7.32934 7.24257L7.24257 7.32934C7.10257 7.46934 7.03656 7.57911 7.00082 7.69701C6.96509 7.81492 6.96509 7.9355 7.00082 8.05341C7.03656 8.17131 7.10257 8.28108 7.24257 8.42108L10.8215 12L7.24257 15.5789C7.10257 15.7189 7.03656 15.8287 7.00082 15.9466C6.96509 16.0645 6.96509 16.1851 7.00082 16.303C7.03656 16.4209 7.10257 16.5307 7.24257 16.6707L7.32934 16.7574C7.46934 16.8974 7.57911 16.9634 7.69701 16.9992C7.81492 17.0349 7.9355 17.0349 8.05341 16.9992C8.17131 16.9634 8.28108 16.8974 8.42108 16.7574L12 13.1785L15.5789 16.7574C15.7189 16.8974 15.8287 16.9634 15.9466 16.9992C16.0645 17.0349 16.1851 17.0349 16.303 16.9992C16.4209 16.9634 16.5307 16.8974 16.6707 16.7574L16.7574 16.6707C16.8974 16.5307 16.9634 16.4209 16.9992 16.303C17.0349 16.1851 17.0349 16.0645 16.9992 15.9466C16.9634 15.8287 16.8974 15.7189 16.7574 15.5789L13.1785 12L16.7574 8.42108C16.8974 8.28108 16.9634 8.17131 16.9992 8.05341C17.0349 7.9355 17.0349 7.81492 16.9992 7.69701C16.9634 7.57911 16.8974 7.46934 16.7574 7.32934L16.6707 7.24257C16.5307 7.10257 16.4209 7.03656 16.303 7.00082C16.1851 6.96509 16.0645 6.96509 15.9466 7.00082C15.8287 7.03656 15.7189 7.10257 15.5789 7.24257L12 10.8215Z"
        fill="#828282"
      />
    </g>
  </svg>
);

export default CancelIcon;
