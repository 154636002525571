import './ConsumptionTypeTitle.scss';
import classNames from 'classnames';
import React from 'react';
import capitalizeFirstLetter from '@utils/helpers/capitalizeFirstLetter';
import Typography from '@components/Typography';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import egressIcon from './icons/egress.svg';
import tasksIcon from './icons/tasks.svg';
import notebooksIcon from './icons/notebooks.svg';

const getConsumptionTypeIcon = (type: ConsumptionTypes) => {
  switch (type) {
    case ConsumptionTypes.Egress:
      return egressIcon;
    case ConsumptionTypes.Notebooks:
      return notebooksIcon;
    default:
      return tasksIcon;
  }
};

interface ConsumptionTypeTitleProps {
  type: ConsumptionTypes;
  className?: string;
}

const getTitleByType = (type: ConsumptionTypes) => {
  if (type === ConsumptionTypes.Notebooks) {
    return 'Applications';
  }
  return capitalizeFirstLetter(type);
};

const ConsumptionTypeTitle: React.FC<ConsumptionTypeTitleProps> = (props) => {
  const { type, className } = props;
  return (
    <div className={classNames('TDB-ConsumptionTypeTitle', `TDB-ConsumptionTypeTitle--${type}`, className)}>
      <div className="TDB-ConsumptionTypeTitle__Icon">
        <img src={getConsumptionTypeIcon(type)} alt={type} />
      </div>
      <Typography fontSize="heading-4" fontWeight="bold" color="neutral-800">
        {getTitleByType(type)}
      </Typography>
    </div>
  );
};

export default ConsumptionTypeTitle;
