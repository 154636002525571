import { useState, useEffect } from 'react';

export const useViewport = () => {
  let defaultValue = 0;
  if (typeof window !== 'undefined') {
    defaultValue = window.innerWidth;
  }
  const [width, setWidth] = useState(defaultValue);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleWindowResize);

      return () => window.removeEventListener('resize', handleWindowResize);
    }
    return () => undefined;
  }, []);

  // Return the width so we can use it in our components
  return { width };
};
