import React from 'react';

const BoxIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.66461 3.52145L4.78706 4.24151C4.89866 4.31309 5.02306 4.34701 5.14643 4.34701C5.36577 4.34701 5.58108 4.23898 5.70832 4.04032C5.90712 3.73051 5.8171 3.31771 5.50713 3.1192L4.38468 2.39914C4.07442 2.20049 3.66207 2.28977 3.46356 2.60033C3.26475 2.91014 3.35478 3.32294 3.66474 3.52145L3.66461 3.52145Z"
      fill="currentColor"
    />
    <path
      d="M4.64097 9.16864C4.58234 8.8054 4.23576 8.55867 3.87672 8.61658L2.56067 8.82878C2.19713 8.88741 1.94998 9.2298 2.00861 9.59303C2.06128 9.92055 2.34446 10.1536 2.66573 10.1536C2.70115 10.1536 2.73701 10.1511 2.77273 10.1451L4.08877 9.93291C4.45231 9.87427 4.69946 9.53189 4.64084 9.16866L4.64097 9.16864Z"
      fill="currentColor"
    />
    <path
      d="M2.60711 6.75117L3.91245 7.02259C3.95828 7.03167 4.00396 7.03628 4.0489 7.03628C4.35812 7.03628 4.63548 6.82022 4.70081 6.50563C4.77566 6.14492 4.54427 5.79212 4.18387 5.7165L2.87853 5.44508C2.51589 5.37083 2.16502 5.60207 2.09017 5.96202C2.01532 6.32273 2.24671 6.67555 2.60711 6.75117Z"
      fill="currentColor"
    />
    <path
      d="M18.8536 4.347C18.9767 4.347 19.1017 4.31307 19.213 4.24149L20.3354 3.52143C20.6452 3.32278 20.7354 2.91013 20.5366 2.60031C20.3378 2.2905 19.925 2.19929 19.6155 2.39913L18.493 3.11919C18.1832 3.31784 18.093 3.73049 18.2919 4.04031C18.4191 4.23896 18.6343 4.347 18.8537 4.347L18.8536 4.347Z"
      fill="currentColor"
    />
    <path
      d="M21.4395 8.82884L20.1234 8.61664C19.7624 8.55801 19.4173 8.80548 19.3592 9.16871C19.3005 9.53195 19.5475 9.87448 19.9112 9.93296L21.2273 10.1452C21.2631 10.151 21.2988 10.1536 21.3343 10.1536C21.6555 10.1536 21.9387 9.9206 21.9914 9.59308C22.05 9.22984 21.803 8.8873 21.4393 8.82883L21.4395 8.82884Z"
      fill="currentColor"
    />
    <path
      d="M19.2989 6.50584C19.3643 6.82027 19.6418 7.0365 19.9508 7.0365C19.9958 7.0365 20.0413 7.03203 20.0873 7.02281L21.3926 6.75139C21.7529 6.67579 21.9844 6.32298 21.9096 5.96226C21.8344 5.6023 21.4815 5.37119 21.1212 5.44532L19.8159 5.71674C19.4556 5.79234 19.2241 6.14513 19.2989 6.50584Z"
      fill="currentColor"
    />
    <path
      d="M19.2864 12.4196C19.1864 12.1664 18.9396 11.9997 18.6665 11.9997H5.33341C5.06005 11.9997 4.8135 12.1664 4.7135 12.4196L3.38019 15.7529C3.30013 15.9596 3.32692 16.1929 3.44686 16.3728C3.57349 16.5528 3.78019 16.6661 4.0001 16.6661H4.66676V21.3326C4.66676 21.6993 4.96675 21.9993 5.33341 21.9993H18.6665C19.0331 21.9993 19.3331 21.6993 19.3331 21.3326V16.6661H19.9998C20.2197 16.6661 20.4264 16.5527 20.553 16.3728C20.6729 16.1927 20.6997 15.9594 20.6197 15.7529L19.2864 12.4196ZM17.9998 20.6663H6.00006V16.6664H17.9998V20.6663Z"
      fill="currentColor"
    />
    <path
      d="M7.71404 11.2433C8.03308 11.059 8.14173 10.6515 7.95749 10.3324C7.54931 9.62665 7.33354 8.82012 7.33354 7.99975C7.33354 5.4269 9.42694 3.33319 12.0001 3.33319C14.5733 3.33319 16.6667 5.4269 16.6667 7.99975C16.6667 8.82012 16.4509 9.62665 16.0427 10.3324C15.8585 10.6515 15.9671 11.0589 16.2862 11.2433C16.3912 11.3044 16.506 11.3331 16.6192 11.3331C16.8493 11.3331 17.0734 11.214 17.197 11.0004C17.7224 10.0915 18.0001 9.05447 18.0001 7.99987C18.0001 4.69129 15.3085 2 12.0002 2C8.69183 2 6.00038 4.69129 6.00038 7.99987C6.00038 9.05447 6.27805 10.0916 6.80349 11.0004C6.98816 11.3187 7.39646 11.4288 7.71432 11.2433H7.71404Z"
      fill="currentColor"
    />
    <path
      d="M13.8936 7.07042H13.0048C12.9886 6.95544 12.9554 6.8533 12.9054 6.76402C12.8553 6.67338 12.7911 6.59627 12.7126 6.53269C12.6341 6.46911 12.5435 6.42041 12.4407 6.38659C12.3392 6.35277 12.229 6.33586 12.1099 6.33586C11.8949 6.33586 11.7075 6.3893 11.5479 6.49617C11.3882 6.60168 11.2645 6.7559 11.1765 6.95882C11.0886 7.16038 11.0446 7.40524 11.0446 7.69338C11.0446 7.98964 11.0886 8.23855 11.1765 8.44012C11.2658 8.64168 11.3903 8.79387 11.5499 8.89668C11.7095 8.99949 11.8942 9.0509 12.1039 9.0509C12.2215 9.0509 12.3304 9.03534 12.4306 9.00423C12.532 8.97311 12.622 8.92779 12.7004 8.86827C12.7789 8.8074 12.8438 8.73367 12.8952 8.64709C12.948 8.56051 12.9845 8.46176 13.0048 8.35083L13.8936 8.35489C13.8706 8.54563 13.8131 8.72961 13.7211 8.90683C13.6305 9.08269 13.508 9.24029 13.3538 9.37963C13.201 9.51761 13.0183 9.62718 12.806 9.70835C12.5949 9.78817 12.3562 9.82807 12.0897 9.82807C11.719 9.82807 11.3876 9.7442 11.0954 9.57646C10.8045 9.40871 10.5745 9.16589 10.4054 8.84798C10.2377 8.53008 10.1538 8.14521 10.1538 7.69338C10.1538 7.2402 10.239 6.85465 10.4095 6.53675C10.5799 6.21885 10.8113 5.9767 11.1035 5.8103C11.3957 5.64256 11.7244 5.55869 12.0897 5.55869C12.3304 5.55869 12.5537 5.59251 12.7593 5.66015C12.9663 5.72778 13.1496 5.82654 13.3092 5.9564C13.4688 6.08492 13.5987 6.24252 13.6988 6.4292C13.8002 6.61589 13.8652 6.82963 13.8936 7.07042Z"
      fill="currentColor"
    />
  </svg>
);

export default BoxIcon;
