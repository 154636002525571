import './OptionWithScaleIcon.scss';
import React from 'react';
import scaleIcon from '../../assets/scale-icon.svg';

const OptionWithScaleIcon: React.FC = (props) => {
  const { children } = props;

  return (
    <div className="TDB-OptionWithScaleIcon">
      <img className="TDB-OptionWithScaleIcon__img" src={scaleIcon} style={{ width: 36 }} alt="" />
      {children}
    </div>
  );
};

export default OptionWithScaleIcon;
