import React from 'react';

const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.0182 22C13.3832 22 14.6676 21.7386 15.8714 21.2157C17.0752 20.6928 18.1377 19.9723 19.0588 19.0543C19.9769 18.1362 20.6974 17.0722 21.2202 15.8623C21.7401 14.6524 22 13.3649 22 12C22 10.632 21.7386 9.3461 21.2157 8.14227C20.6928 6.9354 19.9708 5.87141 19.0497 4.9503C18.1286 4.02918 17.0631 3.30719 15.8532 2.78431C14.6433 2.26144 13.3558 2 11.9909 2C10.6229 2 9.33698 2.25992 8.13315 2.77975C6.92628 3.29959 5.86381 4.02007 4.94574 4.94118C4.02766 5.85925 3.30719 6.92172 2.78431 8.12859C2.26144 9.33242 2 10.6168 2 11.9818C2 13.3467 2.25992 14.6326 2.77975 15.8395C3.29959 17.0464 4.02006 18.1088 4.94118 19.0269C5.85925 19.9419 6.92172 20.6609 8.12859 21.1838C9.33242 21.7036 10.6168 21.9635 11.9818 21.9635L12.0182 22ZM10.673 17.0296C10.5028 17.0296 10.3493 16.9916 10.2125 16.9156C10.0727 16.8396 9.93434 16.7165 9.79754 16.5463L6.9658 13.0579C6.89284 12.9637 6.83508 12.8618 6.79252 12.7524C6.74996 12.6399 6.72868 12.5274 6.72868 12.415C6.72868 12.1839 6.8062 11.9909 6.96124 11.8358C7.11628 11.6778 7.30476 11.5987 7.52668 11.5987C7.66347 11.5987 7.79267 11.6291 7.91427 11.6899C8.03587 11.7507 8.15291 11.8556 8.26539 12.0046L10.6047 15.0278L15.1601 7.72731C15.3485 7.42636 15.5826 7.27588 15.8623 7.27588C16.0751 7.27588 16.2681 7.34276 16.4414 7.47652C16.6116 7.61028 16.6968 7.79419 16.6968 8.02827C16.6968 8.13771 16.6694 8.24867 16.6147 8.36115C16.56 8.47059 16.5037 8.57395 16.446 8.67123L11.4574 16.5144C11.351 16.6755 11.2278 16.7956 11.088 16.8746C10.9451 16.9506 10.7931 16.9886 10.632 16.9886L10.673 17.0296Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default CheckIcon;
