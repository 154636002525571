import './CalculatorHeader.scss';
import React from 'react';
import classNames from 'classnames';
import Typography from '@components/Typography';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';

interface CalculatorHeaderProps {
  title: string;
  className?: string;
  type: ConsumptionTypes;
}

const CalculatorHeader: React.FC<CalculatorHeaderProps> = (props) => {
  const { title, className, children, type } = props;

  return (
    <div className={classNames('TDB-CalculatorHeader', `TDB-CalculatorHeader--${type}`, className)}>
      <Typography className="TDB-CalculatorHeader__title" fontSize="body-lg" fontWeight="bold">
        {title}
      </Typography>
      <div className="TDB-CalculatorHeader__children">{children}</div>
    </div>
  );
};

export default CalculatorHeader;
