import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { useEffect } from 'react';
import Tab from '@components/Tab';
import { PRICING_TABS } from '@constants/PricingTabsTypes';
import links from '@constants/links';
import PricingPlan from '../PricingPlan/PricingPlan';
import ConsumptionOverview from '../../ConsumptionOverview';
import PricingCalculator from '../../PricingCalculator';
import './PricingConsumptionTabs.scss';

type TabTypes = `${PRICING_TABS}`;

interface PricingConsumptionTabsProps {
  initialActiveTab: TabTypes;
}

const PricingConsumptionTabs: React.FC<PricingConsumptionTabsProps> = ({ initialActiveTab }) => {
  const [activeTab, setActiveTab] = React.useState<TabTypes>(initialActiveTab);

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  return (
    <div className="TDB-PricingConsumptionTabs">
      <div className="TDB-PricingConsumptionTabs__tabs-wrapper">
        <div className="TDB-PricingConsumptionTabs__left-divider" />
        <div className="TDB-PricingConsumptionTabs__tabs">
          <AnchorLink to={`${links.pricing}`}>
            <Tab
              id="individuals-plans"
              className="TDB-PricingConsumptionTabs__tab"
              onClick={() => setActiveTab(PRICING_TABS.PLANS)}
              active={activeTab === PRICING_TABS.PLANS}
            >
              Plans
            </Tab>
          </AnchorLink>
          <AnchorLink to={`${links.pricing}?tab=consumptions`}>
            <Tab
              id="organization-plans"
              onClick={() => setActiveTab(PRICING_TABS.CONSUMPTIONS)}
              active={activeTab === PRICING_TABS.CONSUMPTIONS}
            >
              Consumptions
            </Tab>
          </AnchorLink>
        </div>
        <div className="TDB-PricingConsumptionTabs__right-divider" />
      </div>
      {activeTab === PRICING_TABS.PLANS && <PricingPlan />}
      {activeTab === PRICING_TABS.CONSUMPTIONS && (
        <>
          <ConsumptionOverview />
          <PricingCalculator />
        </>
      )}
    </div>
  );
};

export default PricingConsumptionTabs;
