import React from 'react';

const UniversityIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2278 22.4483H22.3047V19.6791C22.3047 19.4343 22.2075 19.1995 22.0344 19.0264C21.8613 18.8533 21.6265 18.756 21.3817 18.756H20.4586V11.3715H21.3817C21.6265 11.3715 21.8613 11.2743 22.0344 11.1011C22.2075 10.928 22.3047 10.6933 22.3047 10.4484V8.6023H23.2278C23.4304 8.6016 23.6272 8.53426 23.7877 8.41066C23.9482 8.28706 24.0637 8.11408 24.1161 7.91839C24.1686 7.72271 24.1553 7.51518 24.0782 7.32783C24.001 7.14049 23.8644 6.98372 23.6893 6.88171L12.6125 0.420244C12.4713 0.33785 12.3108 0.294434 12.1473 0.294434C11.9838 0.294434 11.8233 0.33785 11.6821 0.420244L0.605299 6.88171C0.429699 6.98403 0.292778 7.14145 0.215781 7.32954C0.138783 7.51763 0.126014 7.72587 0.179454 7.92196C0.232894 8.11805 0.349556 8.29102 0.511339 8.41403C0.673122 8.53704 0.87098 8.60322 1.07422 8.6023H1.99728V10.4484C1.99728 10.6933 2.09453 10.928 2.26764 11.1011C2.44075 11.2743 2.67554 11.3715 2.92035 11.3715H3.84342V18.756H2.92035C2.67554 18.756 2.44075 18.8533 2.26764 19.0264C2.09453 19.1995 1.99728 19.4343 1.99728 19.6791V22.4483H1.07422C0.829405 22.4483 0.594619 22.5455 0.42151 22.7187C0.248402 22.8918 0.151151 23.1266 0.151151 23.3714C0.151151 23.6162 0.248402 23.851 0.42151 24.0241C0.594619 24.1972 0.829405 24.2944 1.07422 24.2944H23.2278C23.4726 24.2944 23.7074 24.1972 23.8805 24.0241C24.0536 23.851 24.1509 23.6162 24.1509 23.3714C24.1509 23.1266 24.0536 22.8918 23.8805 22.7187C23.7074 22.5455 23.4726 22.4483 23.2278 22.4483ZM18.6125 18.756H15.8433V11.3715H18.6125V18.756ZM10.3049 18.756V11.3715H13.9971V18.756H10.3049ZM12.151 2.28668L19.8125 6.75617H4.48956L12.151 2.28668ZM20.4586 8.6023V9.52537H3.84342V8.6023H20.4586ZM5.68955 11.3715H8.45875V18.756H5.68955V11.3715ZM3.84342 22.4483V20.6022H20.4586V22.4483H3.84342Z"
      fill="#BEFFF6"
    />
  </svg>
);

export default UniversityIcon;
