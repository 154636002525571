import React from 'react';
import ConsumptionTypeTitle from '../../../components/ConsumptionTypeTitle';
import SlicingCalculator from './SlicingCalculator';
import SQLCalculator from './SQLCalculator';
import UDFCalculator from './UDFCalculator';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';

interface JupyterNotebooksCalculatorProps {
  onSlicingChange: (data: PricingDetailsType) => void;
  onSQLChange: (data: PricingDetailsType) => void;
  onUDFChange: (data: PricingDetailsType) => void;
}

const TasksCalculator: React.FC<JupyterNotebooksCalculatorProps> = (props) => {
  const { onSlicingChange, onSQLChange, onUDFChange } = props;

  return (
    <div className="TDB-CalculatorSection__Wrapper">
      <ConsumptionTypeTitle className="TDB-CalculatorSection__Header" type={ConsumptionTypes.Tasks} />
      <SlicingCalculator onChange={onSlicingChange} />
      <SQLCalculator onChange={onSQLChange} />
      <UDFCalculator onChange={onUDFChange} />
    </div>
  );
};

export default TasksCalculator;
