import './PricingCalculator.scss';
import React, { useMemo, useState } from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Button from '@components/Button';
import { Link } from 'gatsby';
import links from '@constants/links';
import JupyterNotebooksCalculator from './components/JupyterNotebooksCalculator';
import PricingDetails from './components/PricingDetails';
import TasksCalculator from './components/TasksCalculator';
import EgressCalculator from './components/EgressCalculator';
import Image from './assets/TileDB_pricing_calculator.png';

const PricingCalculator: React.FC = () => {
  const [notebooksPricingDetails, setNotebooksPricingDetails] = useState({
    value: 0,
    cost: 0,
    scale: '',
    costScale: '',
  });
  const [slicingPricingDetails, setSlicingPricingDetails] = useState({
    value: 0,
    cost: 0,
    scale: '',
    costScale: '',
  });
  const [SQLPricingDetails, setSQLPricingDetails] = useState({
    value: 0,
    cost: 0,
    scale: '',
    costScale: '',
  });
  const [UDFPricingDetails, setUDFPricingDetails] = useState({
    value: 0,
    cost: 0,
    scale: '',
    costScale: '',
  });
  const [egressPricingDetails, setEgressPricingDetails] = useState({
    value: 0,
    cost: 0,
    scale: '',
    costScale: '',
  });

  const notebookTotalCost = useMemo(
    () => parseFloat((notebooksPricingDetails.value * notebooksPricingDetails.cost).toFixed(4)),
    [notebooksPricingDetails.value, notebooksPricingDetails.cost]
  );
  const slicingTotalCost = useMemo(
    () => parseFloat((slicingPricingDetails.value * slicingPricingDetails.cost).toFixed(4)),
    [slicingPricingDetails.value, slicingPricingDetails.cost]
  );
  const SQLTotalCost = useMemo(
    () => parseFloat((SQLPricingDetails.value * SQLPricingDetails.cost).toFixed(4)),
    [SQLPricingDetails.value, SQLPricingDetails.cost]
  );
  const UDFTotalCost = useMemo(
    () => parseFloat((UDFPricingDetails.value * UDFPricingDetails.cost).toFixed(4)),
    [UDFPricingDetails.value, UDFPricingDetails.cost]
  );
  const egressTotalCost = useMemo(
    () => parseFloat((egressPricingDetails.value * egressPricingDetails.cost).toFixed(4)),
    [egressPricingDetails.value, egressPricingDetails.cost]
  );

  const totalCost = useMemo(
    () => notebookTotalCost + slicingTotalCost + SQLTotalCost + UDFTotalCost + egressTotalCost,
    [notebookTotalCost, slicingTotalCost, SQLTotalCost, UDFTotalCost, egressTotalCost]
  );

  return (
    <div className="TDB-PricingCalculator">
      <Container maxWidth={1069}>
        <Typography className="TDB-PricingCalculator__Title" fontSize="heading-1" fontWeight="bold" color="neutral-900" align="center">
          Pricing calculator
        </Typography>
        <Typography
          className="TDB-PricingCalculator__Description"
          fontSize="heading-5"
          fontWeight="medium"
          color="neutral-600"
          align="center"
        >
          Use the sliders below to help estimate the costs of various queries and computation scenarios.
        </Typography>
        <Link to={links.contact}>
          <Button className="TDB-PricingCalculator__ContactUsButton">Contact Us</Button>
        </Link>
        <div className="TDB-PricingCalculator__Card">
          <div className="TDB-PricingCalculator__Sliders">
            <JupyterNotebooksCalculator onChange={setNotebooksPricingDetails} />
            <TasksCalculator
              onSlicingChange={setSlicingPricingDetails}
              onSQLChange={setSQLPricingDetails}
              onUDFChange={setUDFPricingDetails}
            />
            <EgressCalculator onChange={setEgressPricingDetails} />
          </div>
          <div className="TDB-PricingCalculator__Card__MobileTotalCost">
            <Typography
              className="TDB-PricingCalculator__Card__MobileTotalCost__Title"
              fontSize="body-lg"
              fontWeight="bold"
              color="neutral-600"
            >
              Total cost
            </Typography>
            <Typography
              className="TDB-PricingCalculator__Card__MobileTotalCost__Value"
              fontSize="body-lg"
              fontWeight="bold"
              color="neutral-600"
            >
              $ {totalCost.toFixed(2)}
            </Typography>
          </div>
          <PricingDetails
            notebooksPricingDetails={notebooksPricingDetails}
            slicingPricingDetails={slicingPricingDetails}
            SQLPricingDetails={SQLPricingDetails}
            UDFPricingDetails={UDFPricingDetails}
            egressPricingDetails={egressPricingDetails}
          />
        </div>
        <img className="TDB-PricingCalculator__img" src={Image} alt="pricing calculator" />
      </Container>
    </div>
  );
};

export default PricingCalculator;
