import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PricingDetailsType } from '@custom-types/PricingDetailsType';
import { ConsumptionTypes } from '@constants/ConsumptionTypes';
import InputSelect from '@components/InputSelect';
import pluralize from '@utils/helpers/pluralize';
import CalculatorSection from '../CalculatorSection';
import CalculatorHeader from '../CalculatorSection/CalculatorHeader';
import OptionWithScaleIcon from '../OptionWithScaleIcon';
import ConsumptionTypeTitle from '../../../components/ConsumptionTypeTitle';
import CostBlock from '../CostBlock';

const scaleOptions = [
  {
    label: <OptionWithScaleIcon>Scale: Megabytes</OptionWithScaleIcon>,
    value: 'megabytes',
  },
  {
    label: <OptionWithScaleIcon>Scale: Gigabytes</OptionWithScaleIcon>,
    value: 'gigabytes',
  },
];

const megabytesMarkLabels: any = {
  0: '0MB',
  10: '250MB',
  20: '500MB',
  30: '750MB',
  40: '1GB',
  50: '1.25GB',
  60: '1.5GB',
  70: '1.75GB',
  80: '2GB',
  90: '2.25GB',
  100: '2.5GB',
};

const gigabytesMarkLabels: any = {
  0: '0GB',
  20: '2GB',
  40: '4GB',
  60: '6GB',
  80: '8GB',
  100: '10GB',
  120: '12GB',
  140: '14GB',
  160: '16GB',
  180: '18GB',
  200: '20GB',
  220: '22GB',
  240: '24GB',
  260: '26GB',
};

const costs: any = {
  megabytes: 0.00014,
  gigabytes: 0.14,
};

const scaleDescriptions: any = {
  megabytes: 'megabyte',
  gigabytes: 'gigabyte',
};

const scaleShortDescriptions: any = {
  megabytes: 'MB',
  gigabytes: 'GB',
};

interface SlicingCalculatorProps {
  onChange: (data: PricingDetailsType) => void;
}

const SlicingCalculator: React.FC<SlicingCalculatorProps> = (props) => {
  const { onChange } = props;
  const [gigabytesValue, setGigabytesValue] = useState(15);
  const [megabytesValue, setMegabytesValue] = useState(10);
  const [scaleValue, setScaleValue] = useState(scaleOptions[0]);
  const [cost, setCost] = useState({ value: 0, cost: 0, scale: '', costScale: '' });

  const scale = useMemo(() => {
    return scaleValue.value;
  }, [scaleValue]);

  const markLabels = useMemo(() => {
    if (scaleValue.value === 'megabytes') {
      return megabytesMarkLabels;
    }
    if (scaleValue.value === 'gigabytes') {
      return gigabytesMarkLabels;
    }
    return [];
  }, [scaleValue.value]);

  const value = useMemo(() => {
    let v = 0;
    if (scaleValue.value === 'megabytes') {
      v = megabytesValue;
    } else if (scaleValue.value === 'gigabytes') {
      v = gigabytesValue;
    }
    return v;
  }, [scaleValue, megabytesValue, gigabytesValue]);
  const displayValue = useMemo(() => {
    let v = 0;
    if (scaleValue.value === 'megabytes') {
      v = megabytesValue * 25;
    } else if (scaleValue.value === 'gigabytes') {
      v = gigabytesValue / 10;
    }
    return v;
  }, [scaleValue, megabytesValue, gigabytesValue]);

  useEffect(() => {
    const c = costs[scaleValue.value];
    let costScale = '';
    if (scaleValue.value === 'gigabytes') {
      costScale = 'per GB';
    } else if (scaleValue.value === 'megabytes') {
      costScale = 'per MB';
    }

    const scaleDescr = pluralize(displayValue, scaleDescriptions[scaleValue.value], `${scaleDescriptions[scaleValue.value]}s`);

    setCost({ value: displayValue, cost: c, scale: scaleDescr, costScale });
  }, [displayValue, scaleValue]);

  const max = useMemo(() => {
    if (scaleValue.value === 'megabytes') {
      return 100;
    }
    if (scaleValue.value === 'gigabytes') {
      return 260;
    }
    return 0;
  }, [scaleValue]);

  useEffect(() => {
    onChange(cost);
  }, [cost]);

  const handleSliderChange = useCallback(
    (v: number) => {
      if (scaleValue.value === 'megabytes') {
        setMegabytesValue(v);
      }
      if (scaleValue.value === 'gigabytes') {
        setGigabytesValue(v);
      }
    },
    [scaleValue]
  );

  const highlightMarks = useMemo(() => {
    if (scaleValue.value === 'megabytes') {
      return [40, 80];
    }
    if (scaleValue.value === 'gigabytes') {
      return [100, 200];
    }
    return [];
  }, [scaleValue]);

  return (
    <div className="TDB-CalculatorSection__Wrapper">
      <ConsumptionTypeTitle className="TDB-CalculatorSection__Header" type={ConsumptionTypes.Egress} />
      <CalculatorSection
        type={ConsumptionTypes.Egress}
        header={
          <CalculatorHeader title="Data volume" type={ConsumptionTypes.Egress}>
            <InputSelect options={scaleOptions} value={scaleValue} onChange={(newValue: any) => setScaleValue(newValue)} />
          </CalculatorHeader>
        }
        description="Data retrieved by any operation."
        onSliderChange={handleSliderChange}
        displayValue={{
          value: displayValue,
          description: scaleShortDescriptions[scale],
        }}
        sliderValue={value}
        markLabels={markLabels}
        highlightMarks={highlightMarks}
        min={0}
        max={max}
      />
      <CostBlock cost={cost} type={ConsumptionTypes.Egress} costDescription="Data volume" mobileOnly />
    </div>
  );
};

export default SlicingCalculator;
